import { appConstants } from './app.constant';

const appState = {
  userStatus: {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    error: '',
    isAuthenticated: false,
    user: {},
  },
  toastState: {
    toastType: '',
    duration: 4000,
    message: '',
  },
};

export default (state = appState, action) => {
  switch (action.type) {
    case appConstants.SET_SNACK:
      return { ...state, toastState: action.payload };
    case appConstants.LOGIN_SUCCESS:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isLoggingIn: false,
          error: '',
          user: action.user,
          isAuthenticated: true,
          isVerifying: false,
        },
      };
    case appConstants.LOGIN_FAILURE:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isLoggingIn: false,
          error: action.payload,
          isAuthenticated: false,
        },
      };
    case appConstants.LOGOUT_FAILURE:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isLoggingOut: false,
          error: action.payload,
          isAuthenticated: false,
          isVerifying: false,
          user: {},
        },
      };
    case appConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isLoggingOut: false,
          isAuthenticated: false,
          error: '',
          user: {}
        },
      };
    case appConstants.VERIFY_REQUEST:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isVerifying: true,
          error: '',
        },
      };
    case appConstants.VERIFY_SUCCESS:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          isVerifying: false,
          error: '',
        },
      };
      default:
          return state;
  }
}