import { firebaseDb } from '../../../shared/services/firebaseService';
import { dashboardConstant } from './dashboard.constant'
import { hoursEnum } from '../../../constants/constants';
import { setSnackMessage, logoutUser } from '../../../shared/app.action';
import { snackTypes } from '../../../constants/constants';

export const getGraphData = (month,year) => (dispatch, getState) => {
    dispatch(getOrgId(getState().appState.userStatus.user.uid), month, year)
}

const getOrgId = (uid, month, year) => (dispatch) => {
    firebaseDb.ref('corporate_id/' + uid).once('value')
    .then(res => {
        if(!res.val()) {
            dispatch(setSnackMessage({ type: snackTypes.ERROR, message: 'Invalid Login' }));
            dispatch(logoutUser());
            return;
        }
        dispatch(setOrgId(res.val()));
		dispatch(populateGraphData(res.val()));
		dispatch(getOrgInfo(res.val()))
		dispatch(getInsights(res.val()))
    })
    .catch(err => {
        console.log(err)
    })
}

const getOrgInfo = (orgId) => (dispatch) => {
	firebaseDb.ref('organisations/' + orgId + '/logo').once('value')
    .then(res => {
		dispatch(setOrgLogo(res.val()));
    });
	firebaseDb.ref('organisations/' + orgId + '/name').once('value')
    .then(res => {
		dispatch(setOrgName(res.val()));
	});
}

const populateGraphData = (orgId) => (dispatch) => {
	Promise.all([
        firebaseDb.ref("analytics/organisations/" + orgId).once("value"),
        firebaseDb.ref("analytics/organisation_native_assessment/" + orgId).once("value")
    ]).then((data) => {
		const analytics = decodeAnalytics(data[0].val(), true);
		decodeAssessmentAnalytics(data[1].val(), analytics);
		dispatch(setAnalytics(analytics));
		dispatch(setComputedTotal({
			counsellingTime: analytics.call_duration + analytics.helpline_duration + analytics.message_duration,
			assessmentCount: analytics.assessment_count,
			usersCount: analytics.user_count,
			mostActiveHour: hoursEnum[analytics.most_active_hour],
			mentalHealthScore: analytics.mental_health_score ? analytics.mental_health_score : 0,
		}))
	}).catch((err) => {
		console.log(err)
	})
}

const addValue = (obj, path, val) => {
    const lastKey = path.pop();
    const lastObj = path.reduce((obj, key) =>
        obj[key] = obj[key] || {},
        obj);
    lastObj[lastKey] = lastObj[lastKey] || 0;
    lastObj[lastKey] += val;
}

export const setCharts = (month, year) => (dispatch, getState) => {
	const analytics = getState().dashboard.analytics && getState().dashboard.analytics.cumulative;
	const monthlyData = analytics[year][month] ? analytics[year][month] : {};
	dispatch(setMonthlyActiveTimes(hoursEnum[monthlyData.most_active_hour]));
	dispatch(setKeyMetricGraph(Object.keys(monthlyData.graph.key_metric).map((key) => ({
        name: key,
        value: monthlyData.graph.key_metric[key]
    }))));
	dispatch(setMonthlyAssessmentCount(monthlyData.assessment_count));
	dispatch(setMonthlyUsersCount(monthlyData.user_count));
	dispatch(setMonthlyCouncellingTime(monthlyData.message_duration + monthlyData.call_duration + monthlyData.helpline_duration));
	dispatch(setMonthlyMentalHealth(monthlyData.mental_health_score === -1 ? 'NA' : monthlyData.mental_health_score));
	dispatch(setAssessmentsGraph(Object.keys(monthlyData['graph']['assessment_activity']).map((date) => {
        return {
            date: date,
            value: monthlyData?.['graph']?.['assessment_activity']?.[date],
        }
    })));
    const councellingHeatMapData = [];
    Object.keys(monthlyData['graph']['counselling_heatmap']).map(day => {
        Object.keys(monthlyData['graph']['counselling_heatmap'][day]).map((hour) => {
            councellingHeatMapData.push ({
                group: hour,
                x: day,
                y: monthlyData['graph']['counselling_heatmap'][day][hour]
            });
        })
    });
    dispatch(setCouncellingHeatMap(councellingHeatMapData));
    dispatch(setPsychiatricDomainGraph(monthlyData['graph']['domain_web']));
    const councellingGraphData = [];
    Object.keys(monthlyData['graph']['message_duration']).map((day) => {
        councellingGraphData.push({
            day,
            helpline: monthlyData['graph']['helpline_duration'][day],
            call: monthlyData['graph']['call_duration'][day],
            msg: monthlyData['graph']['message_duration'][day],
        });
    })
    dispatch(setCounsellingGraph(councellingGraphData));
    const allInsights = getState().dashboard.allInsights;
    if (allInsights && allInsights.length) {
        const currMonthInsights = allInsights.filter((insight) => {
            return new Date(insight.timestamp).getMonth() == month && new Date(insight.timestamp).getFullYear() == year;
        });
        dispatch(setMonthlyInsights(currMonthInsights && currMonthInsights.sort(function(a, b){return b.timestamp - a.timestamp})))
    }
}

const decodeAnalytics = (analytics, isOrganisation = false) => {
    const data = {
        "message_count": 0,
        "user_count": 0,
        "message_duration": 0,
        "call_count": 0,
        "call_duration": 0,
        "helpline_count": 0,
        "helpline_duration": 0,
        "most_active_hour": -1,
        "cumulative": {}
    };
    if (isOrganisation) {
        data["assessment_count"] = 0;
        data["mental_health_score"] = -1;
    }
    const hourActivity = Array(24).fill(0);
    const monthlyHourActivity = {};

    for (const accessCode in analytics) {
        const accessCodeAnalytics = analytics[accessCode];
        for (const year in accessCodeAnalytics) {
            const yearAnalytics = accessCodeAnalytics[year];
            initMonths(data["cumulative"], monthlyHourActivity, year, isOrganisation);
            for (const month in yearAnalytics) {
                const monthAnalytics = yearAnalytics[month];
                const cumulative = ["cumulative", year, month];

                for (const date in monthAnalytics) {
                    const dateAnalytics = monthAnalytics[date];
                    for (const hour in dateAnalytics) {
                        const hourAnalytics = dateAnalytics[hour];

                        if (hourAnalytics.key_metrics) {
                            const keyMetricEnum = ['Likely to be well', 'Likely to have a mild disorder', 'Likely to have a moderate disorder', 'Likely to have a severe disorder'];
                            Object.keys(hourAnalytics.key_metrics).forEach((key) => {
                                addValue(data, [...cumulative, "graph", "key_metric", keyMetricEnum[key]], hourAnalytics.key_metrics[key]);
                            })
                            
                        }

                        if (accessCode === "external_helpline") {
                            const count = hourAnalytics["count"];
                            const dur = Math.ceil(hourAnalytics["duration"] / 60);
                            hourActivity[hour] += dur;
                            monthlyHourActivity[year][month][hour] += dur;
                            addValue(data, ["helpline_count"], count);
                            addValue(data, ["helpline_duration"], dur);
                            addValue(data, [...cumulative, "helpline_count"], count);
                            addValue(data, [...cumulative, "helpline_duration"], dur);
                            addValue(data, [...cumulative, "graph", "helpline_activity", date], count);
                            addValue(data, [...cumulative, "graph", "helpline_duration", date], dur);
                            addValue(data, [...cumulative, "graph", "counselling_heatmap", date, hour], dur);
                            continue;
                        }

                        const users = hourAnalytics["users"];
                        addValue(data, ["user_count"], users?.count || 0);
                        addValue(data, [...cumulative, "user_count"], users?.count || 0);

                        const messages = hourAnalytics["messages"];
                        const count = messages ? messages : 0;
                        addValue(data, ["message_count"], count);
                        addValue(data, [...cumulative, "message_count"], count);
                        addValue(data, [...cumulative, "graph", "message_activity", date], count);
                        addValue(data, [...cumulative, "graph", "message_heatmap", date, hour], count);

                        let messageLength = 0;
                        const messageLengths = hourAnalytics["message_length"];
                        for (const userType in messageLengths) {
                            if (userType === "user") {
                                messageLength += messageLengths[userType];
                                continue;
                            }
                            for (const msgType in messageLengths[userType]) {
                                messageLength += messageLengths[userType][msgType];
                            }
                        }
                        if (hourAnalytics["assessment_count"]) {
                            messageLength += hourAnalytics["assessment_count"] * 1200;
                        }
                        const dur = getMessageDuration(messageLength);
                        hourActivity[hour] += dur;
                        monthlyHourActivity[year][month][hour] += dur;
                        addValue(data, ["message_duration"], dur);
                        addValue(data, [...cumulative, "message_duration"], dur);
                        addValue(data, [...cumulative, "graph", "message_duration", date], dur);
                        addValue(data, [...cumulative, "graph", "counselling_heatmap", date, hour], dur);

                        const calls = hourAnalytics["calls"];
                        for (const userType in calls) {
                            const count = calls[userType]["count"];
                            const dur = Math.ceil(calls[userType]["duration"] / 60);
                            hourActivity[hour] += dur;
                            monthlyHourActivity[year][month][hour] += dur;
                            addValue(data, ["call_count"], count);
                            addValue(data, ["call_duration"], dur);
                            addValue(data, [...cumulative, "call_count"], count);
                            addValue(data, [...cumulative, "call_duration"], dur);
                            addValue(data, [...cumulative, "graph", "call_activity", date], count);
                            addValue(data, [...cumulative, "graph", "call_duration", date], dur);
                            addValue(data, [...cumulative, "graph", "counselling_heatmap", date, hour], dur);
                        }

                        const helpline = hourAnalytics['helpline'];
                        if (helpline) {
                            const count = helpline["count"];
                            const dur = Math.ceil(helpline["duration"] / 60);
                            hourActivity[hour] += dur;
                            monthlyHourActivity[year][month][hour] += dur;
                            addValue(data, ["helpline_count"], count);
                            addValue(data, ["helpline_duration"], dur);
                            addValue(data, [...cumulative, "helpline_count"], count);
                            addValue(data, [...cumulative, "helpline_duration"], dur);
                            addValue(data, [...cumulative, "graph", "helpline_activity", date], count);
                            addValue(data, [...cumulative, "graph", "helpline_duration", date], dur);
                            addValue(data, [...cumulative, "graph", "counselling_heatmap", date, hour], dur);
                        }
                    }
                }
            }
        }
    }

    const max = Math.max(...hourActivity);
    if (max > 0) {
        data["most_active_hour"] = hourActivity.indexOf(max);
    }
    for (const yyyy in monthlyHourActivity) {
        for (const mm in monthlyHourActivity[yyyy]) {
            const arr = monthlyHourActivity[yyyy][mm];
            const max = Math.max(...arr);
            if (max > 0) {
                data["cumulative"][yyyy][mm]["most_active_hour"] = arr.indexOf(max);
            }
        }
    }
    return data;
}

const initMonths = (cumulative, monthlyHourActivity, yyyy, isOrganisation = false) => {
    if (cumulative[yyyy]) return;
    monthlyHourActivity[yyyy] = {};
    let maxMonth = 12;
    const now = new Date();
    if (yyyy === '' + now.getFullYear()) {
        maxMonth = now.getMonth() + 1;
    }
    for (let mm = 0; mm < maxMonth; mm++) {
        monthlyHourActivity[yyyy][mm] = Array(24).fill(0);
        addValue(cumulative, [yyyy, mm, "message_count"], 0);
        addValue(cumulative, [yyyy, mm, "message_duration"], 0);
        addValue(cumulative, [yyyy, mm, "call_count"], 0);
        addValue(cumulative, [yyyy, mm, "call_duration"], 0);
        addValue(cumulative, [yyyy, mm, "helpline_count"], 0);
        addValue(cumulative, [yyyy, mm, "helpline_duration"], 0);
        addValue(cumulative, [yyyy, mm, "most_active_hour"], -1);
        if (isOrganisation) {
            addValue(cumulative, [yyyy, mm, "user_count"], 0);
            addValue(cumulative, [yyyy, mm, "assessment_count"], 0);
            addValue(cumulative, [yyyy, mm, "mental_health_score"], -1);
            addValue(cumulative, [yyyy, mm, "graph", "key_metric", "Likely to be well"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "key_metric", "Likely to have a mild disorder"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "key_metric", "Likely to have a moderate disorder"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "key_metric", "Likely to have a severe disorder"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Depression"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Substance Use"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Personality Functioning"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Dissociation"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Repetitive Thoughts and Behaviors"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Memory"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Sleep Problems"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Psychosis"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Suicidal Ideation"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Somatic Symptoms"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Anxiety"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Mania"], 0);
            addValue(cumulative, [yyyy, mm, "graph", "domain_web", "Anger"], 0);
        }
        for (let dd = 1; dd <= daysInMonth(yyyy, mm); dd++) {
            addValue(cumulative, [yyyy, mm, "graph", "message_activity", dd], 0);
            addValue(cumulative, [yyyy, mm, "graph", "message_duration", dd], 0);
            addValue(cumulative, [yyyy, mm, "graph", "call_activity", dd], 0);
            addValue(cumulative, [yyyy, mm, "graph", "call_duration", dd], 0);
            addValue(cumulative, [yyyy, mm, "graph", "helpline_activity", dd], 0);
            addValue(cumulative, [yyyy, mm, "graph", "helpline_duration", dd], 0);
            if (isOrganisation) {
                addValue(cumulative, [yyyy, mm, "graph", "assessment_activity", dd], 0);
            }
            for (let hh = 0; hh < 24; hh++) {
                addValue(cumulative, [yyyy, mm, "graph", "message_heatmap", dd, hh], 0);
                addValue(cumulative, [yyyy, mm, "graph", "counselling_heatmap", dd, hh], 0);
            }
        }
    }
}

const decodeAssessmentAnalytics = (analytics, data) => {
    let allTotal = 0;
    let allCount = 0;
    for (const year in analytics) {
        const yearAnalytics = analytics[year];
        for (const month in yearAnalytics) {
            const monthAnalytics = yearAnalytics[month];
            const cumulative = ["cumulative", year, month];
            let monthTotal = 0;

            for (const date in monthAnalytics["count"]) {
                const dateAnalytics = monthAnalytics["count"][date];
                for (const hour in dateAnalytics) {
                    const count = dateAnalytics[hour];
                    addValue(data, ["assessment_count"], count);
                    addValue(data, [...cumulative, "assessment_count"], count);
                    addValue(data, [...cumulative, "graph", "assessment_activity", date], count);
                    monthTotal += count;
                }
            }

            let monthCount = 0;
            for (const domain in monthAnalytics["domain"]) {
                const count = monthAnalytics["domain"][domain];
                const percentage = Math.ceil(100 * count / monthTotal);
                addValue(data, [...cumulative, "graph", "domain_web", domain], percentage);
                monthCount += count;
            }
            if (monthTotal > 0) {
                const cumulativeScore = 100 - Math.ceil(100 * monthCount / (13 * monthTotal));
                data["cumulative"][year][month]["mental_health_score"] = cumulativeScore;
            }

            allTotal += monthTotal;
            allCount += monthCount;
        }
    }
    if (allTotal > 0) {
        const score = 100 - Math.ceil(100 * allCount / (13 * allTotal));
        data["mental_health_score"] = score;
    }
}

const getMessageDuration = (messageLength) => {
    const charPerMin = 120;
    return Math.ceil(messageLength / charPerMin);
}

const daysInMonth = (yyyy, mm) => {
    return new Date(yyyy, mm + 1, 0).getDate();
}

const getInsights = (orgId) => (dispatch) => {
	firebaseDb.ref('organisations/' + orgId + '/insights').once('value')
    .then(res => {
        const allInsights = Object.values(res.val());
        dispatch(setInsights(allInsights));
        if (allInsights && allInsights.length) {
            const currMonthInsights = allInsights.filter((insight) => {
                return new Date(insight.timestamp).getMonth() == new Date().getMonth() && new Date(insight.timestamp).getFullYear() == new Date().getFullYear();
            });
            dispatch(setMonthlyInsights(currMonthInsights && currMonthInsights.sort(function(a, b){return b.timestamp - a.timestamp})))
        }
	});
}

export const setOrgId = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_ORG_ID,
        payload
    })
}

const setComputedTotal = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_COMPUTED_TOTAL,
        payload
    })
}

const setMonthlyActiveTimes = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_MONTHLY_ACTIVE_TIME,
        payload
    })
}
const setAnalytics = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_ANALYTICS,
        payload
    })
}
const setMonthlyAssessmentCount = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_MONTHLY_ASSESSMENTS,
        payload
    })
}
const setMonthlyUsersCount = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_MONTHLY_USERS,
        payload
    })
}
const setMonthlyMentalHealth = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_MONTHLY_MENTAL_HEALTH,
        payload
    })
}
const setMonthlyCouncellingTime = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_MONTHLY_COUNCELLING_TIME,
        payload
    })
}

const setCounsellingGraph = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_COUNSELLING_GRAPH,
        payload
    })
}

const setKeyMetricGraph = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_KEY_METRIC_GRAPH,
        payload
    })
}
const setCouncellingHeatMap = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_COUNSELLING_HEAT_MAP,
        payload
    })
}
const setAssessmentsGraph = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_ASSESSMENTS_GRAPH,
        payload
    })
}
const setPsychiatricDomainGraph = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_PSYCHIATRIC_DOMAIN_GRAPH,
        payload
    })
}
const setOrgLogo = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_ORG_LOGO,
        payload
    })
}
const setOrgName = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_ORG_NAME,
        payload
    })
}
const setInsights = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_INSIGHTS,
        payload
    })
}
export const resetDashboard = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.RESET_DASHBOARD,
        payload
    })
}

export const setMonthlyInsights = (payload) => (dispatch) => {
    dispatch({
        type: dashboardConstant.SET_MONTHLY_INSIGHT,
        payload
    })
}