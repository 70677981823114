import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RouteGuard = ({
  component: Component,
  isAuthenticated,
  redirectPath,
  ...rest
}) => {
    return(
        <Route
            {...rest}
            render={(props) => (
            <React.Fragment>
                {isAuthenticated ? (
                <Component {...props} />
                ) : (
                <Redirect
                    to={{ pathname: redirectPath, state: { from: props.location } }}
                />
                )}
            </React.Fragment>
            )}
        />
)};

export default RouteGuard;
