import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./shared/combineReducers";
import thunkMiddleware from "redux-thunk";

const composeEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
);