import { appConstants } from './app.constant';
import { snackTypes } from '../constants/constants';
import { firebaseApp } from '../shared/services/firebaseService';
import { resetDashboard } from '../pages/dashboard/shared/dashboard.action';

export const setSnackMessage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: appConstants.SET_SNACK,
      payload: {
        type: payload.type ? payload.type : snackTypes.INFO,
        duration: payload.duration ? payload.duratio : 4000,
        message: payload.message,
      },
    });
  };
};

export const loginSuccess = (user) => (dispatch) => {
  dispatch({
    type: appConstants.LOGIN_SUCCESS,
    user,
  });
};
export const loginFailure = (err) => (dispatch) => {
  dispatch({
    type: appConstants.LOGIN_FAILURE,
    err,
  });
};
export const logoutSuccess = (user) => (dispatch) => {
  dispatch({
    type: appConstants.LOGOUT_SUCCESS,
    user,
  });
};
export const logoutFailure = (err) => (dispatch) => {
  dispatch({
    type: appConstants.LOGOUT_FAILURE,
    err,
  });
};

export const requestVerify = () => (dispatch) => {
  dispatch({
    type: appConstants.VERIFY_REQUEST,
  });
};

export const verifySuccess = () => (dispatch) => {
  dispatch({
    type: appConstants.VERIFY_SUCCESS,
  });
};

export const loginUser = (email, pass) => (dispatch) => {
  dispatch(setSnackMessage({ type: snackTypes.INFO, message: 'Logging in' }));
  dispatch(requestVerify());
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, pass)
    .then((user) => {
      dispatch(
        setSnackMessage({
          type: snackTypes.SUCCESS,
          message: 'Login Successful',
        })
      );
    })
    .catch((err) => {
      dispatch(
        setSnackMessage({ type: snackTypes.ERROR, message: err.message })
      );
      dispatch(logoutFailure(err.message));
    });
};

export const logoutUser = () => (dispatch) => {
  firebaseApp
    .auth()
    .signOut()
    .then(() => {
      dispatch(resetDashboard());
      dispatch(logoutSuccess());
    })
    .catch((err) => {
      dispatch(logoutFailure(err.message));
    });
};

export const verifyUser = () => (dispatch) => {
  dispatch(requestVerify())
  firebaseApp.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(loginSuccess(user));
    }
    dispatch(verifySuccess());
  });
};