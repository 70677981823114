import React, { Suspense } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Loader from './shared/components/Loader/Loader';
import SnackMessage from './shared/components/SnackMessage';
import RouteGuard from './pages/RouteGuard';
const Login = React.lazy(() => import('./pages/login/Login'));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));

function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Nunito',
        'sans-serif',
      ].join(','),
    },
  });
  const isVerifying = useSelector(state => state.appState.userStatus.isVerifying);
  const isAuthenticated = useSelector(state => state.appState.userStatus.isAuthenticated);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader />}>
        <SnackMessage />
        {
          !isVerifying ?
          <Router>
            <Switch>
              <Route exact path='/' component={Login} />
              <RouteGuard path='/dashboard'
                isAuthenticated={isAuthenticated}
                redirectPath='/'
                component={Dashboard} />
            </Switch>
          </Router>
          :
          <Loader />
        }
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
