import { dashboardConstant } from './dashboard.constant';

const dashboardState = {
    orgId: null,
    orgName: null,
    orgLogo: null,
    counsellingGraph: null,
    counsellingHeatMap: null,
    assessmentsGraph: null,
    keyMetricGraph: null,
    psychiatricDomainGraph: null,
    allInsights: null,
    monthlyInsights: null,
    totalData:{},
    analytics: null,
    monthlyCouncellingTime: undefined,
    monthlyActiveTime: undefined,
    monthlyAssessments: undefined,
    monthlyUsers: undefined,
    monthlyMentalHealth: undefined,
};

export default (state = dashboardState, action) => {
  switch (action.type) {
    case dashboardConstant.SET_ORG_ID:
      return { ...state, orgId: action.payload };
    case dashboardConstant.SET_COMPUTED_TOTAL:
      return { ...state, totalData: action.payload };
    case dashboardConstant.SET_ANALYTICS:
      return { ...state, analytics: action.payload };
    case dashboardConstant.SET_ORG_NAME:
      return { ...state, orgName: action.payload };
    case dashboardConstant.SET_ORG_LOGO:
      return { ...state, orgLogo: action.payload };
    case dashboardConstant.SET_INSIGHTS:
      return { ...state, allInsights: action.payload };
    case dashboardConstant.SET_MONTHLY_COUNCELLING_TIME:
      return { ...state, monthlyCouncellingTime: action.payload };
    case dashboardConstant.SET_MONTHLY_MENTAL_HEALTH:
      return { ...state, monthlyMentalHealth: action.payload };
    case dashboardConstant.SET_MONTHLY_ACTIVE_TIME:
      return { ...state, monthlyActiveTime: action.payload };
    case dashboardConstant.SET_MONTHLY_ASSESSMENTS:
      return { ...state, monthlyAssessments: action.payload };
    case dashboardConstant.SET_MONTHLY_USERS:
      return { ...state, monthlyUsers: action.payload };
    case dashboardConstant.SET_KEY_METRIC_GRAPH:
      return { ...state, keyMetricGraph: action.payload };
    case dashboardConstant.SET_COUNSELLING_GRAPH:
      return { ...state, counsellingGraph: action.payload };
    case dashboardConstant.SET_COUNSELLING_HEAT_MAP:
      return { ...state, counsellingHeatMap: action.payload };
    case dashboardConstant.SET_ASSESSMENTS_GRAPH:
      return { ...state, assessmentsGraph: action.payload };
    case dashboardConstant.SET_PSYCHIATRIC_DOMAIN_GRAPH:
      return { ...state, psychiatricDomainGraph: action.payload };
    case dashboardConstant.RESET_DASHBOARD:
      return { ...dashboardState };
    case dashboardConstant.SET_MONTHLY_INSIGHT:
      return { ...state, monthlyInsights: action.payload};
    default:
      return state;
  }
}