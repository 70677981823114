export const dashboardConstant = {
    SET_ORG_ID: 'SET_ORG_ID',
    SET_COMPUTED_TOTAL: 'SET_COMPUTED_TOTAL',
    SET_ANALYTICS: 'SET_ANALYTICS',
    SET_ORG_LOGO: 'SET_ORG_LOGO',
    SET_ORG_NAME: 'SET_ORG_NAME',
    SET_INSIGHTS: 'SET_INSIGHTS',
    SET_MONTHLY_COUNCELLING_TIME: 'SET_MONTHLY_COUNCELLING_TIME',
    SET_MONTHLY_MENTAL_HEALTH: 'SET_MONTHLY_MENTAL_HEALTH',
    SET_MONTHLY_ACTIVE_TIME: 'SET_MONTHLY_ACTIVE_TIME',
    SET_MONTHLY_ASSESSMENTS: 'SET_MONTHLY_ASSESSMENTS',
    SET_COUNSELLING_GRAPH: 'SET_COUNSELLING_GRAPH',
    SET_COUNSELLING_HEAT_MAP: 'SET_COUNSELLING_HEAT_MAP',
    SET_ASSESSMENTS_GRAPH: 'SET_ASSESSMENTS_GRAPH',
    SET_PSYCHIATRIC_DOMAIN_GRAPH: 'SET_PSYCHIATRIC_DOMAIN_GRAPH',
    RESET_DASHBOARD: 'RESET_DASHBOARD',
    SET_MONTHLY_INSIGHT: 'SET_MONTHLY_INSIGHT',
    SET_MONTHLY_USERS: 'SET_MONTHLY_USERS',
    SET_KEY_METRIC_GRAPH: 'SET_KEY_METRIC_GRAPH',
}